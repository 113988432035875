import React, { useState, useEffect } from "react";
import M from "materialize-css";
import { useHistory } from "react-router-dom";
import Config from "../../../config/Config";
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";
import Select from "react-select";

const AddMarksheet = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [students, setStudents] = useState([]);
  const [isAdded, setIsAdded] = useState(false);

  const [formData, setFormData] = useState({});

  // Submit Handler
  const submitHandler = (evt) => {
    setLoading(true);
    evt.preventDefault();

    fetch(Config.SERVER_URL + "/marksheets", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });

            setIsAdded(!isAdded);
            // history.goBack();
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // get Courses
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/courses?limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });

            setCourses(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Students
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/students?limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: `${v.name}-${v.fatherName}`, value: v._id };
            });

            setStudents(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // Calculate total & percentage
  useEffect(() => {
    let dcaPractical = Number(formData.dcaPractical) || 0;
    let dcaTheory = Number(formData.dcaTheory) || 0;

    let tallyPractical = Number(formData.tallyPractical) || 0;
    let tallyTheory = Number(formData.tallyTheory) || 0;

    let dtpPractical = Number(formData.dtpPractical) || 0;
    let dtpTheory = Number(formData.dtpTheory) || 0;

    let total = dcaPractical + dcaTheory;
    total += tallyTheory + tallyPractical;
    total += dtpPractical + dtpTheory;

    let percentage = total / 6;

    setFormData({
      ...formData,
      totalMarks: total,
      percentage: percentage,
    });
  }, [
    formData.dcaPractical,
    formData.dcaTheory,
    formData.tallyPractical,
    formData.tallyTheory,
    formData.dtpPractical,
    formData.dtpTheory,
  ]);

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}
        <Breadcrumb title={"MARKSHEETS"} pageTitle={"Add Marksheet"} />

        {/* Add Mrksheeet Form */}
        <div className="row">
          <div className={"col-md-11 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* Details */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>MARKSHEET DETAILS</h3>
                </div>

                {/* SELECT STUDENT */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT STUDENT
                  </label>
                  <div className="">
                    <Select
                      options={students}
                      onChange={({ value }) => {
                        setFormData({ ...formData, student: value });
                      }}
                    />
                  </div>
                </div>

                {/* SELECT COURSE */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT COURSE
                  </label>
                  <div className="">
                    <Select
                      options={courses}
                      onChange={({ value }) => {
                        setFormData({ ...formData, course: value });
                      }}
                    />
                  </div>
                </div>

                {/* SERIAL NUMBER */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>SERIAL NUMBER</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        serialNumber: evt.target.value,
                      })
                    }
                    value={formData.serialNumber}
                    className="form-control"
                    placeholder={"Enter serial number"}
                  />
                </div>

                {/* REGISTRATION NUMBER */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>REGISTRATION NUMBER</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        regNumber: evt.target.value,
                      })
                    }
                    value={formData.regNumber}
                    className="form-control"
                    placeholder={"Enter registration number"}
                  />
                </div>

                {/* ISSUE DATE */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>ISSUE DATE</label>
                  <input
                    type="date"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        dateOfIssue: evt.target.value,
                      })
                    }
                    value={formData.dateOfIssue}
                    className="form-control"
                    placeholder={"Enter registration number"}
                  />
                </div>
              </div>

              {/* Dca Marks Details */}
              <div className={"row shadow-sm bg-white py-3 mt-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>DCA MARKS DETAILS</h3>
                </div>

                {/* THEORY MARKS */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>THEORY MARKS</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        dcaTheory: evt.target.value,
                      })
                    }
                    value={formData.dcaTheory}
                    className="form-control"
                    placeholder={"Enter theory marks"}
                  />
                </div>

                {/* PRACTICAL MARKS */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>PRACTICAL MARKS</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        dcaPractical: evt.target.value,
                      })
                    }
                    value={formData.dcaPractical}
                    className="form-control"
                    placeholder={"Enter practical marks"}
                  />
                </div>
              </div>

              {/* TALLY MARKS DETAILS */}
              <div className={"row shadow-sm bg-white py-3 mt-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>TALLY MARKS DETAILS</h3>
                </div>

                {/* THEORY MARKS */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>THEORY MARKS</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        tallyTheory: evt.target.value,
                      })
                    }
                    value={formData.tallyTheory}
                    className="form-control"
                    placeholder={"Enter theory marks"}
                  />
                </div>

                {/* PRACTICAL MARKS */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>PRACTICAL MARKS</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        tallyPractical: evt.target.value,
                      })
                    }
                    value={formData.tallyPractical}
                    className="form-control"
                    placeholder={"Enter practical marks"}
                  />
                </div>
              </div>

              {/* DTP MARKS DETAILS */}
              <div className={"row shadow-sm bg-white py-3 mt-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>DTP MARKS DETAILS</h3>
                </div>

                {/* THEORY MARKS */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>THEORY MARKS</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        dtpTheory: evt.target.value,
                      })
                    }
                    value={formData.dtpTheory}
                    className="form-control"
                    placeholder={"Enter theory marks"}
                  />
                </div>

                {/* PRACTICAL MARKS */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>PRACTICAL MARKS</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        dtpPractical: evt.target.value,
                      })
                    }
                    value={formData.dtpPractical}
                    className="form-control"
                    placeholder={"Enter practical marks"}
                  />
                </div>
              </div>

              {/* TOTAL MARKS DETAILS */}
              <div className={"row shadow-sm bg-white py-3 mt-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>TOTAL MARKS DETAILS</h3>
                </div>

                {/* TOTAL MARKS */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>TOTAL MARKS</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        totalMarks: evt.target.value,
                      })
                    }
                    value={formData.totalMarks}
                    className="form-control"
                    placeholder={"Enter total marks"}
                  />
                </div>

                {/* PERCENTAGE */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>PERCENTAGE</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        percentage: evt.target.value,
                      })
                    }
                    value={formData.percentage}
                    className="form-control"
                    placeholder={"Enter percentage"}
                  />
                </div>

                <div className={"form-group col-md-12"}>
                  <button className="btn btn-info rounded" type={"submit"}>
                    {loading ? (
                      <Spinner />
                    ) : (
                      <div>
                        <i className="fas fa-plus"></i> Add Marksheet
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMarksheet;
