import React from "react";
import date from "date-and-time";

export default React.forwardRef((props, ref) => {
  let course = "";
  if (props.courseFullName) {
    course = `${props.courseFullName} (${props.courseName})`;
  } else {
    course = props.courseName;
  }

  return (
    <div
      ref={ref}
      style={{
        backgroundImage: `${
          props.sign
            ? 'url("/assets/images/new/marksheet-format-with-sign.jpg")'
            : 'url("/assets/images/new/marksheet-format.jpg")'
        }`,
      }}
      className="adca-marksheet"
    >
      <div className="serial-no">
        <p className="">{props.serialNumber}</p>
      </div>
      <div className="student-name">
        <p className="">{props.studentName}</p>
      </div>
      <div className="father-name">
        <p className="">{props.fatherName}</p>
      </div>
      <div className="certificate-no">
        <p className="">{props.regNumber}</p>
      </div>
      <div className="course-name">
        <p className="">{course}</p>
      </div>

      <div className="course-duration">
        <p className="">{props.courseDuration}</p>
      </div>

      <div className="course-percentage">
        <p className="">{`${props.percentage}%`}</p>
      </div>

      <div className="dca-theory">
        <p className="">{props.dcaTheory}</p>
      </div>

      <div className="dca-practical">
        <p className="">{props.dcaPractical}</p>
      </div>

      <div className="dca-total">
        <p className="">
          {Number(props.dcaTheory) + Number(props.dcaPractical)}
        </p>
      </div>

      <div className="tally-theory">
        <p className="">{props.tallyTheory}</p>
      </div>

      <div className="tally-practical">
        <p className="">{props.tallyPractical}</p>
      </div>

      <div className="tally-total">
        <p className="">
          {Number(props.tallyTheory) + Number(props.tallyPractical)}
        </p>
      </div>

      <div className="dtp-theory">
        <p className="">{props.dtpTheory}</p>
      </div>

      <div className="dtp-practical">
        <p className="">{props.dtpPractical}</p>
      </div>

      <div className="dtp-total">
        <p className="">
          {Number(props.dtpTheory) + Number(props.dtpPractical)}
        </p>
      </div>

      <div className="total-marks">
        <p className="">{props.totalMarks}</p>
      </div>

      <div className="issue-date">
        <p className="">
          {date.format(new Date(props.dateOfIssue), "DD-MM-YYYY")}
        </p>
      </div>
    </div>
  );
});
