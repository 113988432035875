import React, { useState, useEffect } from "react";
import M from "materialize-css";
import { useHistory, useParams } from "react-router-dom";
import Config from "../../../config/Config";
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";
import Select from "react-select";
import date from "date-and-time";
const EditMarksheet = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);

  const [isAdded, setIsAdded] = useState(false);

  const [formData, setFormData] = useState({
    studentName: "",
    fatherName: "",
    description: "",
  });

  // Submit Handler
  const submitHandler = (evt) => {
    setLoading(true);
    evt.preventDefault();

    // Data for update
    const updatedData = {
      serialNumber: formData.serialNumber,
      regNumber: formData.regNumber,
      dateOfIssue: formData.dateOfIssue,
      dcaTheory: formData.dcaTheory,
      dcaPractical: formData.dcaPractical,

      tallyTheory: formData.tallyTheory,
      tallyPractical: formData.tallyPractical,

      dtpTheory: formData.dtpTheory,
      dtpPractical: formData.dtpPractical,

      totalMarks: formData.totalMarks,
      percentage: formData.percentage,
    };

    fetch(Config.SERVER_URL + "/marksheets/" + formData._id, {
      method: "PUT",
      body: JSON.stringify(updatedData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });

            setIsAdded(!isAdded);
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // get marksheets
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/marksheets/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setFormData(result.body);
            setSelectedCourse({
              label: result?.body?.course?.name,
              value: result?.body?.course?._id,
            });

            setSelectedStudent({
              label: result?.body?.student?.name,
              value: result?.body?.student?._id,
            });
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // Calculate total & percentage
  useEffect(() => {
    let dcaPractical = Number(formData.dcaPractical) || 0;
    let dcaTheory = Number(formData.dcaTheory) || 0;

    let tallyPractical = Number(formData.tallyPractical) || 0;
    let tallyTheory = Number(formData.tallyTheory) || 0;

    let dtpPractical = Number(formData.dtpPractical) || 0;
    let dtpTheory = Number(formData.dtpTheory) || 0;

    let total = dcaPractical + dcaTheory;
    total += tallyTheory + tallyPractical;
    total += dtpPractical + dtpTheory;

    let percentage = total / 6;

    setFormData({
      ...formData,
      totalMarks: total,
      percentage: percentage,
    });
  }, [
    formData.dcaPractical,
    formData.dcaTheory,
    formData.tallyPractical,
    formData.tallyTheory,
    formData.dtpPractical,
    formData.dtpTheory,
  ]);

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}

        <Breadcrumb title={"MARKSHEET"} pageTitle={"Update Marksheet"} />

        {/* Update Form */}
        <div className="row">
          <div className={"col-md-11 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* Marksheet Details */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>MARKSHEET DETAILS</h3>
                </div>

                {/* SELECT STUDENT */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT STUDENT
                  </label>
                  <div className="">
                    <Select
                      isDisabled={true}
                      options={selectedStudent}
                      value={selectedStudent}
                      onChange={({ value }) => {
                        setFormData({ ...formData, student: value });
                      }}
                    />
                  </div>
                </div>

                {/* SELECT COURSE */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT COURSE
                  </label>
                  <div className="">
                    <Select
                      isDisabled={true}
                      options={selectedCourse}
                      value={selectedCourse}
                      onChange={({ value }) => {
                        setFormData({ ...formData, course: value });
                      }}
                    />
                  </div>
                </div>

                {/* SERIAL NUMBER */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>SERIAL NUMBER</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        serialNumber: evt.target.value,
                      })
                    }
                    value={formData.serialNumber}
                    className="form-control"
                    placeholder={"Enter serial number"}
                  />
                </div>

                {/* REG/CERTIFICATE NUMBER */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>
                    REG/CERTIFICATE NUMBER
                  </label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        regNumber: evt.target.value,
                      })
                    }
                    value={formData.regNumber}
                    className="form-control"
                    placeholder={"Enter registration number"}
                  />
                </div>

                {/* DATE OF ISSUE */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>DATE OF ISSUE</label>
                  <input
                    type="date"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        dateOfIssue: evt.target.value,
                      })
                    }
                    value={date.format(
                      new Date(formData.dateOfIssue),
                      "YYYY-MM-DD"
                    )}
                    className="form-control"
                    placeholder={"Enter date"}
                  />
                </div>
              </div>

              {/* Dca Marks Details */}
              <div className={"row shadow-sm bg-white py-3 mt-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>DCA MARKS DETAILS</h3>
                </div>

                {/* THEORY MARKS */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>THEORY MARKS</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        dcaTheory: evt.target.value,
                      })
                    }
                    value={formData.dcaTheory}
                    className="form-control"
                    placeholder={"Enter theory marks"}
                  />
                </div>

                {/* PRACTICAL MARKS */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>PRACTICAL MARKS</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        dcaPractical: evt.target.value,
                      })
                    }
                    value={formData.dcaPractical}
                    className="form-control"
                    placeholder={"Enter practical marks"}
                  />
                </div>
              </div>

              {/* TALLY MARKS DETAILS */}
              <div className={"row shadow-sm bg-white py-3 mt-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>TALLY MARKS DETAILS</h3>
                </div>

                {/* THEORY MARKS */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>THEORY MARKS</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        tallyTheory: evt.target.value,
                      })
                    }
                    value={formData.tallyTheory}
                    className="form-control"
                    placeholder={"Enter theory marks"}
                  />
                </div>

                {/* PRACTICAL MARKS */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>PRACTICAL MARKS</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        tallyPractical: evt.target.value,
                      })
                    }
                    value={formData.tallyPractical}
                    className="form-control"
                    placeholder={"Enter practical marks"}
                  />
                </div>
              </div>

              {/* DTP MARKS DETAILS */}
              <div className={"row shadow-sm bg-white py-3 mt-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>DTP MARKS DETAILS</h3>
                </div>

                {/* THEORY MARKS */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>THEORY MARKS</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        dtpTheory: evt.target.value,
                      })
                    }
                    value={formData.dtpTheory}
                    className="form-control"
                    placeholder={"Enter theory marks"}
                  />
                </div>

                {/* PRACTICAL MARKS */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>PRACTICAL MARKS</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        dtpPractical: evt.target.value,
                      })
                    }
                    value={formData.dtpPractical}
                    className="form-control"
                    placeholder={"Enter practical marks"}
                  />
                </div>
              </div>

              {/* TOTAL MARKS DETAILS */}
              <div className={"row shadow-sm bg-white py-3 mt-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>TOTAL MARKS DETAILS</h3>
                </div>

                {/* TOTAL MARKS */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>TOTAL MARKS</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        totalMarks: evt.target.value,
                      })
                    }
                    value={formData.totalMarks}
                    className="form-control"
                    placeholder={"Enter total marks"}
                  />
                </div>

                {/* PERCENTAGE */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>PERCENTAGE</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        percentage: evt.target.value,
                      })
                    }
                    value={formData.percentage}
                    className="form-control"
                    placeholder={"Enter percentage"}
                  />
                </div>

                <div className={"form-group col-md-12"}>
                  <button
                    disabled={loading}
                    className="btn btn-info rounded"
                    type={"submit"}
                  >
                    {loading ? (
                      <Spinner />
                    ) : (
                      <div>
                        <i className="fas fa-refresh"></i> Update Marksheet
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMarksheet;
