import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PageNoteFound from "./pages/PageNotFound";
import Home from "./pages/Home";
import CertificateDetails from "./pages/CertificateDetails";

const Routing = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/certificateDetails" component={CertificateDetails} />

      <Route exact path="/*" component={PageNoteFound} />
    </Switch>
  );
};
const AdminRouter = () => {
  return (
    <div id="main-wrapper">
      <Router>
        <Routing />
      </Router>
    </div>
  );
};

export default AdminRouter;
