import React, { useState, useEffect } from "react";
import M from "materialize-css";
import { Link } from "react-router-dom";
import Config from "../../config/Config";

function Dashboard() {
  // All Certificates
  const [totalCertificateLoading, setTotalCertificatesLoading] = useState(true);
  const [totalCertificates, setTotalCertificates] = useState(0);

  // All Students
  const [totalStudentsLoading, setTotalStudentsLoading] = useState(true);
  const [totalStudents, setTotalStudents] = useState(0);

  // All Marksheets
  const [totalMarksheetsLoading, setTotalMarksheetsLoading] = useState(true);
  const [totalMarksheets, setTotalMarksheets] = useState(0);

  // Getting Total Certificate
  useEffect(() => {
    fetch(Config.SERVER_URL + "/certificates?limit=1", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setTotalCertificatesLoading(false);
          if (result.status == 200) {
            setTotalCertificates(result.totalRecords || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTotalCertificatesLoading(false);
        }
      );
  }, []);

  // Getting Total Students
  useEffect(() => {
    fetch(Config.SERVER_URL + "/students?limit=1", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setTotalStudentsLoading(false);
          if (result.status == 200) {
            setTotalStudents(result.totalRecords || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTotalStudentsLoading(false);
        }
      );
  }, []);

  // Getting Total Marksheets
  useEffect(() => {
    fetch(Config.SERVER_URL + "/marksheets?limit=1", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setTotalMarksheetsLoading(false);
          if (result.status == 200) {
            setTotalMarksheets(result.totalRecords || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTotalMarksheetsLoading(false);
        }
      );
  }, []);

  return (
    <div>
      <div className="page-wrapper px-0 pt-0">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Container fluid  --> */}
        {/* <!-- ============================================================== --> */}
        <div className="container-fluid">
          {/* <!-- ============================================================== --> */}
          {/* <!-- Bread crumb and right siLine toggle --> */}
          {/* <!-- ============================================================== --> */}
          <div className="row page-titles mb-0">
            <div className="col-md-5 col-8 align-self-center">
              <h3 className="text-themecolor">Dashboard</h3>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
          {/* <!-- End Bread crumb and right sidebar toggle --> */}

          {/* <!-- Card Section --> */}
          <div
            className={"row page-titles px-1 my-0 shadow-none"}
            style={{ background: "none" }}
          >
            <div className="col-md-12">
              <div className="d-flex justify-content-between">
                <h3 className="card-title mb-4">Stats Overview</h3>
              </div>
            </div>

            {/* Card Design */}
            <div className={"col-md-12"}>
              <div className={"row"}>
                {/* Total Students */}
                <div className={"col-md-3"}>
                  <div className={"card bg-white border-0"}>
                    <Link to={"/admin/students"}>
                      <div className={"card-body py-1"}>
                        <div className={"float-left"}>
                          <i
                            className={
                              "mdi mdi-account-multiple v-big-icon text-info"
                            }
                          />
                        </div>
                        <div className={"float-right text-right m-2"}>
                          <h2
                            className={"text-info"}
                            style={{ fontSize: "30px" }}
                          >
                            {totalStudentsLoading ? (
                              <div className={"text-center"}>
                                <span
                                  className="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            ) : (
                              totalStudents
                            )}
                          </h2>
                          <span className={"text-info h6"}>Total Students</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                {/* Total Certificates */}
                <div className={"col-md-3"}>
                  <div className={"card bg-white border-0"}>
                    <Link to={"/admin/certificates"}>
                      <div className={"card-body py-1"}>
                        <div className={"float-left"}>
                          <i
                            className={
                              "mdi mdi-account-multiple v-big-icon text-info"
                            }
                          />
                        </div>
                        <div className={"float-right text-right m-2"}>
                          <h2
                            className={"text-info"}
                            style={{ fontSize: "30px" }}
                          >
                            {totalCertificateLoading ? (
                              <div className={"text-center"}>
                                <span
                                  className="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            ) : (
                              totalCertificates
                            )}
                          </h2>
                          <span className={"text-info h6"}>
                            Total Certificates
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                {/* Total Marksheets */}
                <div className={"col-md-3"}>
                  <div className={"card bg-white border-0"}>
                    <Link to={"/admin/marksheets"}>
                      <div className={"card-body py-1"}>
                        <div className={"float-left"}>
                          <i
                            className={
                              "mdi mdi-account-multiple v-big-icon text-info"
                            }
                          />
                        </div>
                        <div className={"float-right text-right m-2"}>
                          <h2
                            className={"text-info"}
                            style={{ fontSize: "30px" }}
                          >
                            {totalMarksheetsLoading ? (
                              <div className={"text-center"}>
                                <span
                                  className="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            ) : (
                              totalMarksheets
                            )}
                          </h2>
                          <span className={"text-info h6"}>
                            Total Marksheets
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Row --> */}
          {/* .............. */}
          {/* <!-- ============================================================== --> */}
        </div>
        {/* <!-- ============================================================== --> */}
        {/* <!-- End Container fluid  --> */}
        {/* <!-- footer --> */}
        {/* <!-- ============================================================== --> */}
        <footer className="footer">© 2021 Esta Global</footer>
        {/* <!-- ============================================================== --> */}
        {/* <!-- End footer --> */}
        {/* <!-- ============================================================== --> */}
      </div>
    </div>
  );
}

export default Dashboard;
