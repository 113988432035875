import React, { useState, useEffect, useRef } from "react";
import M from "materialize-css";
import { useHistory, useParams } from "react-router-dom";
import Config from "../../../config/Config";
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";
import Select from "react-select";
import date from "date-and-time";
import AdcaMarksheet from "../../components/AdcaMarksheet";
import { useReactToPrint } from "react-to-print";

const DownloadMarksheet = () => {
  const { id, sign } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState([]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [marksheet, setMarksheet] = useState({
    studentName: "",
    fatherName: "",
    description: "",
  });

  // get marksheets
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/marksheets/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setMarksheet(result.body);
            document.title = `${result?.body?.student?.name?.replaceAll(
              " ",
              "-"
            )}-${result?.body?.course?.name?.replaceAll(" ", "-")}-MARKSHEET`;
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}

        <Breadcrumb
          title={"DOWNLOAD MARKSHEET"}
          pageTitle={"Download Marksheet"}
        />

        {/* Update Form */}
        <div className="row">
          <div className={"col-md-11 mx-auto"}>
            {/* Marksheet Details */}
            <div className={"row shadow-sm bg-white py-3"}>
              <AdcaMarksheet
                ref={componentRef}
                serialNumber={marksheet.serialNumber}
                regNumber={marksheet.regNumber}
                courseFullName={marksheet?.course?.fullName}
                courseDuration={"1 Year"}
                percentage={marksheet.percentage}
                courseName={marksheet?.course?.name}
                studentName={marksheet?.student?.name}
                fatherName={marksheet?.student?.fatherName}
                dcaTheory={marksheet.dcaTheory}
                dcaPractical={marksheet.dcaPractical}
                tallyTheory={marksheet.tallyTheory}
                tallyPractical={marksheet.tallyPractical}
                dtpTheory={marksheet.dtpTheory}
                dtpPractical={marksheet.dtpPractical}
                totalMarks={marksheet.totalMarks}
                dateOfIssue={marksheet.dateOfIssue}
                documentTitle="Marksheet"
                sign={sign}
              />

              <div className={"form-group col-md-12 text-center"}>
                <button
                  className="btn btn-danger rounded-0"
                  onClick={handlePrint}
                >
                  Print this out!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadMarksheet;
