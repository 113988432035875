import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import M from "materialize-css";
import Config from "../../../config/Config";
import date from "date-and-time";
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";

const StudentDetails = () => {
  const history = useHistory();
  const { id } = useParams();
  const [studentLoaded, setStudentLoaded] = useState(false);
  const query = new URLSearchParams(history.location.search);

  const tab = query.get("tab");
  const [activeTab, setActiveTab] = useState(tab || "profile");

  const [studentCertificates, setStudentCertificates] = useState([]);
  const [certificatesLoading, setCerfificatesLoading] = useState(true);

  const [studentMarksheets, setStudentMarksheets] = useState([]);
  const [marksheetsLoading, setMarksheetsLoading] = useState(true);

  const [user, setUser] = useState({
    shippingAddresses: [],
    wallet: {
      history: [],
      totalAmount: 0,
    },
  });

  // get User Details
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/students/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setUser(result.body);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setStudentLoaded(true);
        },
        (error) => {
          setStudentLoaded(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Certificate Details
  useEffect(() => {
    setCerfificatesLoading(true);
    fetch(`${Config.SERVER_URL}/certificates/?student=${id}&limit=10&page=1`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setStudentCertificates(result.body);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setCerfificatesLoading(false);
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setCerfificatesLoading(false);
        }
      );
  }, []);

  // get Marksheet Details
  useEffect(() => {
    setMarksheetsLoading(true);
    fetch(`${Config.SERVER_URL}/marksheets/?student=${id}&limit=10&page=1`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setStudentMarksheets(result.body);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setMarksheetsLoading(false);
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setMarksheetsLoading(false);
        }
      );
  }, []);

  const tabClickHandler = (tabName) => {
    setActiveTab(tabName);
    const url = history.location.pathname;
    history.push(`${url}?tab=${tabName}`);
  };

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}
        <Breadcrumb title={"STUDENT"} pageTitle={"Student Details"} />
        {/* Details */}

        {studentLoaded ? (
          <div className={"row"}>
            <div className="col-md-12">
              <div className="card shadow-sm border-0">
                <div className="card-body">
                  {/* Tabs */}
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {/* Profile */}
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => {
                          tabClickHandler("profile");
                        }}
                        className={`nav-link ${
                          activeTab == "profile" ? "active" : null
                        }`}
                        id="profile-tab"
                        data-toggle="tab"
                        data-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="true"
                      >
                        Profile
                      </button>
                    </li>

                    {/* Certificates */}
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => {
                          tabClickHandler("certificates");
                        }}
                        className={`nav-link ${
                          activeTab == "certificates" ? "active" : null
                        }`}
                        id="certificates-tab"
                        data-toggle="tab"
                        data-target="#certificates"
                        type="button"
                        role="tab"
                        aria-controls="certificates"
                        aria-selected="false"
                      >
                        Certificate
                        <span className="badge badge-danger">
                          {studentCertificates.length}
                        </span>
                      </button>
                    </li>

                    {/* Marksheet */}
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => {
                          tabClickHandler("marksheets");
                        }}
                        className={`nav-link ${
                          activeTab == "marksheets" ? "active" : null
                        }`}
                        id="marksheets-tab"
                        data-toggle="tab"
                        data-target="#marksheets"
                        type="button"
                        role="tab"
                        aria-controls="marksheets"
                        aria-selected="false"
                      >
                        Marksheet
                        <span className="badge badge-danger">
                          {studentMarksheets.length}
                        </span>
                      </button>
                    </li>
                  </ul>

                  {/* Details */}
                  <div className="tab-content" id="myTabContent">
                    {/* Profile */}
                    <div
                      className={`tab-pane fade show ${
                        activeTab == "profile" ? "active" : null
                      }`}
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          {/* Personal Details */}
                          <div className="card shadow-none border-0">
                            <div className="card-body">
                              <div className="d-flex">
                                <div className="py-3">
                                  <img
                                    style={{
                                      height: "100px",
                                      width: "100px",
                                    }}
                                    src={
                                      !user.gender
                                        ? "/assets/images/users/not-available.png"
                                        : user.gender == "male"
                                        ? "/assets/images/users/man.png"
                                        : "/assets/images/users/woman.png"
                                    }
                                    alt=""
                                  />
                                </div>

                                <div className="p-2 ml-3 ">
                                  <h4 className="p-0 m-0">{user.name}</h4>

                                  <h5>
                                    <a href={`mailto:${user.email}`}>
                                      {user.email}
                                    </a>
                                  </h5>
                                  <h5>
                                    <a href={`tel:${user.mobile}`}>
                                      {user.mobile}
                                    </a>
                                  </h5>

                                  <h5>
                                    Account Status:
                                    {user.status ? (
                                      <span className="badge badge-info">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="badge badge-danger">
                                        Disabled
                                      </span>
                                    )}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 border-top">
                          {/* Student Address */}
                          <div className="card shadow-none border-0">
                            <div className="card-body">
                              <h4 className="">Student Address</h4>
                              <div className="">
                                <p className="p-0 m-0">
                                  <span className="font-weight-normal">
                                    Name :
                                  </span>
                                  <span> {user.name}</span>
                                </p>
                                <p className="p-0 m-0">
                                  <span className="font-weight-normal">
                                    Address :
                                  </span>
                                  <span> {user.address || "N/A"}</span>
                                </p>
                                <p className="p-0 m-0">
                                  <span className="font-weight-normal">
                                    City :
                                  </span>
                                  <span> {user.city || "N/A"}</span>
                                </p>
                                <p className="p-0 m-0">
                                  <span className="font-weight-normal">
                                    Landmark :
                                  </span>
                                  <span> {user.landmark || "N/A"}</span>
                                </p>
                                <p className="p-0 m-0">
                                  <span className="font-weight-normal">
                                    State :
                                  </span>
                                  <span> {user.state || "N/A"}</span>
                                </p>
                                <p className="p-0 m-0">
                                  <span className="font-weight-normal">
                                    Pincode :
                                  </span>
                                  <span> {user.pincode || "N/A"}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Certificates */}
                    <div
                      className={`tab-pane fade show ${
                        activeTab == "certificates" ? "active" : null
                      }`}
                      id="certificates"
                      role="tabpanel"
                      aria-labelledby="certificates-tab"
                    >
                      <div className="row">
                        <div className="col-md-12 table-responsive">
                          <div className="card shadow-sm border-0 mt-3">
                            <div className="col-md-12 text-right">
                              <Link
                                to="/admin/certificate/add"
                                className={"btn btn-info"}
                              >
                                Add Certificate
                              </Link>
                            </div>

                            {certificatesLoading ? (
                              <div className={"bg-white p-3 text-center"}>
                                <Spinner />
                              </div>
                            ) : (
                              <div class="card-body">
                                {studentCertificates?.length ? (
                                  <div class="table-responsive">
                                    <table class="table bg-white">
                                      <thead>
                                        <tr>
                                          <th>#SN</th>
                                          <th>STUDENT</th>
                                          <th>Certificate</th>
                                          <th>Certificate Number</th>
                                          <th>Date Of Issues</th>
                                          <th>Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {studentCertificates.map(
                                          (data, index) => {
                                            return (
                                              <tr key={index}>
                                                <td> {data.serialNumber} </td>
                                                <td> {data?.student?.name} </td>
                                                <td>
                                                  <Link
                                                    to={`/admin/certificate/download/${data?._id}`}
                                                  >
                                                    {data?.course?.name}
                                                  </Link>
                                                </td>
                                                <td>{data.regNumber}</td>
                                                <td>
                                                  {date.format(
                                                    new Date(
                                                      data.certificateIssuesDate
                                                    ),
                                                    "DD-MM-YYYY"
                                                  )}
                                                </td>

                                                <td>
                                                  <Link
                                                    class="btn btn-info"
                                                    to={`/admin/certificate/download/${data._id}`}
                                                  >
                                                    Without Sign
                                                  </Link>
                                                </td>

                                                <td>
                                                  <Link
                                                    class="btn btn-danger"
                                                    to={`/admin/certificate/download/${data._id}/sign`}
                                                  >
                                                    With Sign
                                                  </Link>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  <div className="alert alert-danger h6">
                                    Certificate Not Purchased.
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Marksheet */}
                    <div
                      className={`tab-pane fade show ${
                        activeTab == "marksheets" ? "active" : null
                      }`}
                      id="marksheets"
                      role="tabpanel"
                      aria-labelledby="marksheets-tab"
                    >
                      <div className="row">
                        <div className="col-md-12 table-responsive">
                          <div className="card shadow-sm border-0 mt-3">
                            <div className="col-md-12 text-right">
                              <Link
                                to="/admin/marksheet/add"
                                className={"btn btn-info"}
                              >
                                Add Marksheet
                              </Link>
                            </div>
                            {marksheetsLoading ? (
                              <div className={"bg-white p-3 text-center"}>
                                <Spinner />
                              </div>
                            ) : (
                              <div class="card-body">
                                {studentMarksheets?.length ? (
                                  <div class="table-responsive">
                                    <table class="table bg-white">
                                      <thead>
                                        <tr>
                                          <th>#SN</th>
                                          <th>Course</th>
                                          <th>Certificate Number</th>
                                          <th>Issue Date</th>
                                          <th>Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {studentMarksheets.map(
                                          (data, index) => {
                                            return (
                                              <tr key={index}>
                                                <td> {data.serialNumber} </td>
                                                <td> {data?.student?.name} </td>
                                                <td>
                                                  <Link
                                                    to={`/admin/marksheet/download/${data?._id}`}
                                                  >
                                                    {data?.course?.name}
                                                  </Link>
                                                </td>

                                                <td>{data.regNumber}</td>

                                                <td>
                                                  {date.format(
                                                    new Date(data.dateOfIssue),
                                                    "DD-MM-YYYY"
                                                  )}
                                                </td>
                                                <td>
                                                  <Link
                                                    class="btn btn-info"
                                                    to={`/admin/marksheet/download/${data._id}`}
                                                  >
                                                    Without Sign
                                                  </Link>
                                                </td>

                                                <td>
                                                  <Link
                                                    class="btn btn-danger"
                                                    to={`/admin/marksheet/download/${data._id}/sign`}
                                                  >
                                                    With Sign
                                                  </Link>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  <div className="alert alert-danger h6">
                                    Marksheet Not Available.
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-12 m-auto">
            <div className={"bg-white p-3 text-center"}>
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
              Loading..
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentDetails;
