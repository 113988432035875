import React, { createContext, useReducer, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import LeftNavigation from "./LeftNavigation";
import TopNavigation from "./TopNavigation";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";

import { initialState, adminReducer } from "../reducer/AdminReducer";

import PageNoteFound from "./pages/PageNotFound";

// import ParentCategory from "./pages/ParentCategory";
// import Category from "./pages/Category";

// ================   Admin  =====================
import Profile from "./pages/Profile";

// ================   Courses  =====================
import AddCourse from "./pages/courses/AddCourse";
import CourseList from "./pages/courses/CourseList";
import EditCourse from "./pages/courses/EditCourse";
import AddCourseFromCSV from "./pages/courses/AddCourseFromCSV";
import EditCourseFromCSV from "./pages/courses/EditCourseFromCSV";

// ================   Certificate  =====================
import AddCertificte from "./pages/certificates/AddCertificte";
import CertificateList from "./pages/certificates/CertificateList";
import EditCertificte from "./pages/certificates/EditCertificte";
import AddCertificateFromCSV from "./pages/certificates/AddCertificateFromCSV";
import EditCertificateFromCSV from "./pages/certificates/EditCertificateFromCSV";

// ================   Marksheet  =====================
import AddMarksheet from "./pages/marksheets/AddMarksheet";
import MarksheetList from "./pages/marksheets/MarksheetList";
import EditMarksheet from "./pages/marksheets/EditMarksheet";
import AddMarksheetFromCSV from "./pages/marksheets/AddMarksheetFromCSV";
import EditMarksheetFromCSV from "./pages/marksheets/EditMarksheetFromCSV";
import DownloadMarksheet from "./pages/marksheets/DownloadMarksheet";

// Student
import StudentList from "./pages/students/StudentList";
import AddStudent from "./pages/students/AddStudent";
import AddStudentFromCSV from "./pages/students/AddStudentFromCSV";
import EditStudent from "./pages/students/EditStudent";
import StudentDetails from "./pages/students/StudentDetails";

import ForgotPassword from "./pages/ForgotPassword";
import EnterOtp from "./pages/EnterOtp";
import CreateNewPassword from "./pages/CreateNewPassword";
import Footer from "./Footer";

// Inquires
import AddInquires from "./pages/inquiries/AddInquires";
import InquiryList from "./pages/inquiries/InquiryList";
import EditInquiry from "./pages/inquiries/EditInquiry";
import DownloadCertificate from "./pages/certificates/DownloadCertificate";

// Create Context
export const AdminContext = createContext();

// Create Context
const Routing = () => {
  const history = useHistory();
  // Branch Context
  const { state, dispatch } = useContext(AdminContext);
  useEffect(() => {
    const admin = JSON.parse(localStorage.getItem("admin"));
    if (admin) {
      dispatch({ type: "ADMIN", payload: admin });
      // history.push("/")
    } else {
      history.push("/admin/login");
    }
  }, []);

  return (
    <Switch>
      <Route exact path="/admin" component={Dashboard} />
      <Route exact path="/admin/login" component={Login} />
      <Route exact path="/admin/forgot-password" component={ForgotPassword} />
      <Route exact path="/admin/enter-otp" component={EnterOtp} />
      <Route
        exact
        path="/admin/create-password"
        component={CreateNewPassword}
      />{" "}
      <Route exact path="/admin/profile" component={Profile} />
      {/* Inquires */}
      <Route exact path="/admin/inquires" component={InquiryList} />
      <Route exact path="/admin/inquiry/add" component={AddInquires} />
      <Route exact path="/admin/inquiry/edit/:id" component={EditInquiry} />
      {/* Customer */}
      <Route exact path="/admin/students" component={StudentList} />
      <Route
        exact
        path="/admin/student/addByCSV"
        component={AddStudentFromCSV}
      />
      <Route exact path="/admin/student/add" component={AddStudent} />
      <Route exact path="/admin/student/edit/:id" component={EditStudent} />
      <Route
        exact
        path="/admin/student/details/:id"
        component={StudentDetails}
      />
      {/* Courses */}
      <Route exact path="/admin/courses" component={CourseList} />
      <Route exact path="/admin/course/add" component={AddCourse} />
      <Route exact path="/admin/course/addByCSV" component={AddCourseFromCSV} />
      <Route exact path="/admin/course/edit/:id" component={EditCourse} />
      <Route
        exact
        path="/admin/course/editByCSV"
        component={EditCourseFromCSV}
      />
      {/* Certificates */}
      <Route exact path="/admin/certificates" component={CertificateList} />
      <Route exact path="/admin/certificate/add" component={AddCertificte} />
      <Route
        exact
        path="/admin/certificate/addByCSV"
        component={AddCertificateFromCSV}
      />
      <Route
        exact
        path="/admin/certificate/edit/:id"
        component={EditCertificte}
      />
      <Route
        exact
        path="/admin/certificate/editByCSV"
        component={EditCertificateFromCSV}
      />
      <Route
        exact
        path="/admin/certificate/download/:id/:sign?"
        component={DownloadCertificate}
      />
      {/* Marksheets */}
      <Route exact path="/admin/marksheets" component={MarksheetList} />
      <Route exact path="/admin/marksheet/add" component={AddMarksheet} />
      <Route
        exact
        path="/admin/marksheet/addByCSV"
        component={AddMarksheetFromCSV}
      />
      <Route exact path="/admin/marksheet/edit/:id" component={EditMarksheet} />
      <Route
        exact
        path="/admin/marksheet/editByCSV"
        component={EditMarksheetFromCSV}
      />
      <Route
        exact
        path="/admin/marksheet/download/:id/:sign?"
        component={DownloadMarksheet}
      />
      {/* Not Found */}
      <Route exact path="/*" component={PageNoteFound} />
    </Switch>
  );
};
const AdminRouter = () => {
  const [state, dispatch] = useReducer(adminReducer, initialState);
  return (
    <div id="main-wrapper">
      <AdminContext.Provider value={{ state: state, dispatch: dispatch }}>
        <Router>
          <TopNavigation />
          <LeftNavigation />

          <Routing />
          <Footer />
        </Router>
      </AdminContext.Provider>
    </div>
  );
};

export default AdminRouter;
