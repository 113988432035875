import React, { useState, useEffect } from "react";
import M from "materialize-css";
import { useHistory, useParams } from "react-router-dom";
import Config from "../../../config/Config";
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";
import Select from "react-select";
import date from "date-and-time";
const EditCertificte = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState([]);

  const [courses, setCourses] = useState([]);
  const [isAdded, setIsAdded] = useState(false);

  const [formData, setFormData] = useState({
    description: "",
  });

  // Submit Handler
  const submitHandler = (evt) => {
    setLoading(true);
    evt.preventDefault();

    // Data for update
    const updatedData = {
      serialNumber: formData.serialNumber,
      regNumber: formData.regNumber,
      course: selectedCourse.value,
      duration: formData.duration,
      accuracy: formData.accuracy,
      avgSpeed: formData.avgSpeed,
      grade: formData.grade,
      batchStartDate: formData.batchStartDate,
      batchEndDate: formData.batchEndDate,
      certificateIssuesDate: formData.certificateIssuesDate,
      description: formData.description,
    };

    fetch(Config.SERVER_URL + "/certificates/" + formData._id, {
      method: "PUT",
      body: JSON.stringify(updatedData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });

            setIsAdded(!isAdded);
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // get Certificate
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/certificates/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setFormData(result.body);
            console.log(result?.body?.course);
            setSelectedCourse({
              label: result?.body?.course?.name,
              value: result?.body?.course?._id,
            });
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Courses
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/courses?page=0&limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });

            setCourses(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}

        <Breadcrumb title={"CERTIFICATE"} pageTitle={"Update Certificate"} />

        {/* Update Form */}
        <div className="row">
          <div className={"col-md-11 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* Certificate Details */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>CERTIFICATE DETAILS</h3>
                </div>

                {/* SELECT COURSE */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT COURSE
                  </label>
                  <div className="">
                    <Select
                      options={courses}
                      value={selectedCourse}
                      onChange={(value) => {
                        setSelectedCourse(value);
                      }}
                    />
                  </div>
                </div>

                {/* SERIAL NUMBER */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>SERIAL NUMBER</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        serialNumber: evt.target.value,
                      })
                    }
                    value={formData.serialNumber}
                    className="form-control"
                    placeholder={"Enter serial number"}
                  />
                </div>

                {/* REG/CERTIFICATE NUMBER */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>
                    REG/CERTIFICATE NUMBER
                  </label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        regNumber: evt.target.value,
                      })
                    }
                    value={formData.regNumber}
                    className="form-control"
                    placeholder={"Enter registration number"}
                  />
                </div>

                {/* SESSION */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>SESSION</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        session: evt.target.value,
                      })
                    }
                    value={formData.session}
                    className="form-control"
                    placeholder={"Enter session year"}
                  />
                </div>

                {/* ACCURACY */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>ACCURACY</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        accuracy: evt.target.value,
                      })
                    }
                    value={formData.accuracy}
                    className="form-control"
                    placeholder={"90%"}
                  />
                </div>

                {/* AVARAGE SPEED */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>AVARAGE SPEED</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        avgSpeed: evt.target.value,
                      })
                    }
                    value={formData.avgSpeed}
                    className="form-control"
                    placeholder={"30 WPM"}
                  />
                </div>

                {/* GRADE */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>GRADE</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        grade: evt.target.value,
                      })
                    }
                    value={formData.grade}
                    className="form-control"
                    placeholder={"A"}
                  />
                </div>

                {/* BATCH START DATE */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>BATCH START DATE</label>
                  <input
                    type="date"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        batchStartDate: evt.target.value,
                      })
                    }
                    value={date.format(
                      new Date(formData.batchStartDate),
                      "YYYY-MM-DD"
                    )}
                    className="form-control"
                    placeholder={"Enter date"}
                  />
                </div>

                {/* BATCH END DATE */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>BATCH END DATE</label>
                  <input
                    type="date"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        batchEndDate: evt.target.value,
                      })
                    }
                    value={date.format(
                      new Date(formData.batchEndDate),
                      "YYYY-MM-DD"
                    )}
                    className="form-control"
                    placeholder={"Enter date"}
                  />
                </div>

                {/* CERTIFICATE ISSUES DATE */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>
                    CERTIFICATE ISSUES DATE
                  </label>
                  <input
                    type="date"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        certificateIssuesDate: evt.target.value,
                      })
                    }
                    value={date.format(
                      new Date(formData.certificateIssuesDate),
                      "YYYY-MM-DD"
                    )}
                    className="form-control"
                    placeholder={"Enter date"}
                  />
                </div>

                {/* DESCRIPTION */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>DESCRIPTION</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        description: evt.target.value,
                      })
                    }
                    value={formData.description}
                    className="form-control"
                    placeholder={"Enter description"}
                  />
                </div>

                <div className={"form-group col-md-12"}>
                  <button
                    disabled={loading}
                    className="btn btn-info rounded"
                    type={"submit"}
                  >
                    {loading ? (
                      <Spinner />
                    ) : (
                      <div>
                        <i className="fas fa-refresh"></i> Update Certificate
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCertificte;
