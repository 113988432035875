import React from "react";
import date from "date-and-time";

export default React.forwardRef((props, ref) => {
  let course = "";
  if (props.courseFullName) {
    course = `${props.courseFullName} (${props.courseName})`;
  } else {
    course = props.courseName;
  }

  let printFontSize = "20px";
  let topPosition = "61.5%";

  if (props.printFontSize) {
    if (props.printFontSize > 16) {
      topPosition = "54%";
    }
    printFontSize = `${props.printFontSize}px`;
  }

  return (
    <div
      ref={ref}
      style={{
        backgroundImage: `${
          props.sign
            ? 'url("/assets/images/new/adca-format-with-sign.jpg")'
            : 'url("/assets/images/new/adca-format.jpg")'
        }`,
      }}
      className="adca-certificate"
    >
      <div className="serial-no">
        <p className="">{props.serialNumber}</p>
      </div>
      <div className="student-name">
        <p className="">{props.studentName}</p>
      </div>
      <div className="father-name">
        <p className="">{props.fatherName}</p>
      </div>
      <div className="certificate-no">
        <p className="">{props.regNumber}</p>
      </div>
      <div className="course-name" style={{ top: topPosition }}>
        <p className="" style={{ fontSize: printFontSize }}>
          {course}
        </p>
      </div>
      <div className="batch-start-date">
        <p className="">
          {date.format(new Date(props.batchStartDate), "DD-MM-YYYY")}
        </p>
      </div>
      <div className="batch-end-date">
        <p className="">
          {date.format(new Date(props.batchEndDate), "DD-MM-YYYY")}
        </p>
      </div>
      <div className="grade">
        <p className="">{props.grade}</p>
      </div>
      <div className="issue-date">
        <p className="">
          {date.format(new Date(props.certificateIssuesDate), "DD-MM-YYYY")}
        </p>
      </div>
    </div>
  );
});
