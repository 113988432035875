import React, { useState } from "react";
import { useFormik } from "formik";
import { certificateSchema } from "../yupSchemas";
import { toast } from "react-toastify";
import Config from "../../config/Config";
import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        regNumber: "",
      },
      onSubmit: async (values, helpers) => {
        setLoading(true);
        try {
          const response = await fetch(
            `${Config.SERVER_URL}/certificates?regNumber=${values.regNumber}`
          );
          const result = await response.json();
          if (result.status === 200) {
            if (result.body.length) {
              history.push({
                pathname: "/certificateDetails",
                certificate: result.body[0],
              });
            } else {
              helpers.setErrors({ regNumber: "Oops! no data available." });
            }
          } else {
            helpers.setErrors(result.errors);
          }
          setLoading(false);
        } catch (error) {
          toast.error(error);
          setLoading(false);
        }
      },
      validationSchema: certificateSchema,
    });

  return (
    <>
      <div className="container-fluid" style={{ height: "100vh" }}>
        <div className="row" style={{ height: "100vh" }}>
          <div className="col-md-6 m-auto bg-white p-4 text-center">
            <img
              src="/assets/images/logo.png"
              alt=""
              className="img img-fluid"
            />
            <h2 className="my-4 text-left">Certificate Verification</h2>
            <form className={"form-material"} onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter certificate number"
                  name={"regNumber"}
                  value={values.regNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className="form-error">
                  {errors.regNumber && touched.regNumber
                    ? errors.regNumber
                    : null}
                </p>
              </div>
              <div className="text-center">
                <button
                  disabled={loading}
                  className={"btn btn-danger rounded-0"}
                >
                  {loading ? (
                    <div>
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading..
                    </div>
                  ) : (
                    <div>Verify Certificate</div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
