import React, { useState } from "react";
import date from "date-and-time";
import { useHistory } from "react-router-dom";

const CertificateDetails = (props) => {
  const history = useHistory();
  const { certificate } = props.location;
  if (!certificate) {
    history.replace("/");
  }
  return (
    <>
      <div className="container-fluid" style={{ height: "100vh" }}>
        <div className="row" style={{ height: "100vh" }}>
          <div className="col-md-6 m-auto bg-white p-4">
            <img
              src="/assets/images/logo.png"
              alt=""
              className="img img-fluid"
            />

            <div className="mt-2">
              {certificate ? (
                <div className="col-md-12">
                  <div className="text-center">
                    <img
                      style={{ height: "80px" }}
                      src="/assets/images/tick.png"
                      alt=""
                    />
                  </div>
                  <table className="mt-3 table">
                    <tr>
                      <td>Student Name</td>
                      <td>
                        {certificate.studentName || certificate?.student?.name}
                      </td>
                    </tr>
                    <tr>
                      <td>Fatder Name</td>
                      <td>
                        {certificate.fatherName ||
                          certificate?.student?.fatherName}
                      </td>
                    </tr>

                    <tr>
                      <td>Course</td>
                      <td>{certificate?.course?.name}</td>
                    </tr>

                    <tr>
                      <td>Duration</td>
                      <td>
                        {date.format(
                          new Date(certificate.batchStartDate),
                          "DD/MMM/YYYY"
                        )}
                        -
                        {date.format(
                          new Date(certificate.batchEndDate),
                          "DD/MMM/YYYY"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Date of Issue</td>
                      <td>
                        {date.format(
                          new Date(certificate.certificateIssuesDate),
                          "DD-MMM-YYYY"
                        )}
                      </td>
                    </tr>
                  </table>
                </div>
              ) : (
                <div className="col-md-12 text-center">
                  <img
                    style={{ height: "80px" }}
                    src="/assets/images/remove.png"
                    alt=""
                  />
                  <h2>Oops Error!</h2>
                  <p className="form-error">Certificate number is invalid !</p>
                  <p className="form-error">
                    If you have any query call{" "}
                    <a href="tel:9117162463" className="link">
                      9117162463
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CertificateDetails;
