import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import M from "materialize-css";
import Config from "../../../config/Config";

import Breadcrumb from "../../components/Breadcrumb";

function AddStudent() {
  const history = useHistory();

  // State Variable
  const [isAddLoaded, setIsAddLoaded] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    fatherName: "",
    email: "",
    mobile: "",
    refPersonName: "",
  });

  // Submit Handler
  const submitHandler = (evt) => {
    setIsAddLoaded(false);
    evt.preventDefault();

    fetch(Config.SERVER_URL + "/students", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsAddLoaded(true);
        },
        (error) => {
          setIsAddLoaded(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  return (
    <div className="page-wrapper px-0 pt-0">
      <div className={"container-fluid"}>
        {/* Bread crumb and right sidebar toggle */}
        <Breadcrumb title={"STUDENTS"} pageTitle={"Add Student"} />

        {/* Course Form */}
        <div className="row mt-2">
          <div className={"col-md-12 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* STUDENT DETAILS */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>STUDENT DETAILS</h3>
                </div>
                {/* STUDENT NAME */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    STUDENT NAME
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={(evt) => {
                      setFormData({ ...formData, name: evt.target.value });
                    }}
                    className="form-control"
                    placeholder={"Enter student name"}
                  />
                </div>

                {/* FATHER NAME */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    FATHER NAME
                  </label>
                  <input
                    type="text"
                    value={formData.fatherName}
                    onChange={(evt) => {
                      setFormData({
                        ...formData,
                        fatherName: evt.target.value,
                      });
                    }}
                    className="form-control"
                    placeholder={"Enter father name"}
                  />
                </div>

                {/* MOBILE */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    MOBILE
                  </label>
                  <input
                    type="text"
                    value={formData.mobile}
                    onChange={(evt) => {
                      setFormData({
                        ...formData,
                        mobile: evt.target.value,
                      });
                    }}
                    className="form-control"
                    placeholder={"Enter mobile number"}
                  />
                </div>

                {/* EMAIL */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    EMAIL
                  </label>
                  <input
                    type="text"
                    value={formData.email}
                    onChange={(evt) => {
                      setFormData({
                        ...formData,
                        email: evt.target.value,
                      });
                    }}
                    className="form-control"
                    placeholder={"Enter email address"}
                  />
                </div>
                {/* REFRENCE PERSON NAME */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    REFRENCE PERSON NAME
                  </label>
                  <input
                    type="text"
                    value={formData.refPersonName}
                    onChange={(evt) => {
                      setFormData({
                        ...formData,
                        refPersonName: evt.target.value,
                      });
                    }}
                    className="form-control"
                    placeholder={"Enter ref person name"}
                  />
                </div>

                {/* SUBMIT BUTTON */}
                <div className={"form-group col-md-12"}>
                  <button
                    className="btn btn-info rounded px-3 py-2"
                    type={"submit"}
                  >
                    {isAddLoaded ? (
                      <div>
                        <i className="fas fa-plus"></i> Add Student
                      </div>
                    ) : (
                      <div>
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading..
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddStudent;
