import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import M from "materialize-css";
import Config from "../../../config/Config";
import Breadcrumb from "../../components/Breadcrumb";

function EditStudent() {
  const history = useHistory();
  const { id } = useParams();
  // State Variable
  const [isUpdated, setIsUpdated] = useState(true);

  const [formData, setFormData] = useState({
    name: "",
    fullName: "",
    slug: "",
    duration: "",
    printFontSize: "",
    certificateFileName: "",
  });

  // Submit Handler
  const submitHandler = (evt) => {
    setIsUpdated(false);
    evt.preventDefault();
    const courseData = {
      name: formData.name,
      fatherName: formData.fatherName,
      mobile: formData.mobile,
      email: formData.email,
      refPersonName: formData.refPersonName,
      status: formData.status,
    };

    fetch(`${Config.SERVER_URL}/students/${formData._id}`, {
      method: "PUT",
      body: JSON.stringify(courseData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsUpdated(true);
        },
        (error) => {
          setIsUpdated(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // get course
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/students/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setFormData(result.body);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, [id]);

  return (
    <div className="page-wrapper px-0 pt-0">
      <div className={"container-fluid"}>
        {/* Bread crumb and right sidebar toggle */}
        <Breadcrumb title={"STUDENTS"} pageTitle={"Add Student"} />

        {/* End Bread crumb and right sidebar toggle */}

        {/* Course Form */}
        <div className="row mt-2">
          <div className={"col-md-12 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* STUDENT DETAILS */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>STUDENT DETAILS</h3>
                </div>

                {/* STUDENT NAME */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    STUDENT NAME
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={(evt) => {
                      setFormData({ ...formData, name: evt.target.value });
                    }}
                    className="form-control"
                    placeholder={"Enter student name"}
                  />
                </div>

                {/* FATHER NAME */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    FATHER NAME
                  </label>
                  <input
                    type="text"
                    value={formData.fatherName}
                    onChange={(evt) => {
                      setFormData({
                        ...formData,
                        fatherName: evt.target.value,
                      });
                    }}
                    className="form-control"
                    placeholder={"Enter father name"}
                  />
                </div>

                {/* MOBILE NUMBER */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    MOBILE NUMBER
                  </label>
                  <input
                    type="text"
                    value={formData.mobile}
                    onChange={(evt) =>
                      setFormData({ ...formData, mobile: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"Enter mobile number"}
                  />
                </div>

                {/* EMAIL */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    EMAIL
                  </label>
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(evt) =>
                      setFormData({ ...formData, email: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"Enter email address"}
                  />
                </div>

                {/* REFRENCE PERSON NAME */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    REFRENCE PERSON NAME
                  </label>
                  <input
                    type="text"
                    value={formData.refPersonName}
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        refPersonName: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Enter name"}
                  />
                </div>

                {/* SELECT STATUS */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT STATUS
                  </label>
                  <select
                    name=""
                    id=""
                    value={formData.status}
                    onChange={(evt) => {
                      setFormData({ ...formData, status: evt.target.value });
                    }}
                    className="form-control"
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Disabled</option>
                  </select>
                </div>

                {/* SUBMIT BUTTON */}
                <div className={"form-group col-md-12"}>
                  <button
                    className="btn btn-info rounded px-3 py-2"
                    type={"submit"}
                  >
                    {isUpdated ? (
                      <div>
                        <i className="fas fa-plus"></i> Update Student
                      </div>
                    ) : (
                      <div>
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading..
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditStudent;
