import React from "react";

// import User from './components/user/User'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import leftNavigation from './components/user/LeftNavigation'
import AdminRouter from "./admin/AdminRouter";
import WebRouter from "./web/WebRouter";

function App(props) {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={"/admin"} component={AdminRouter} />
        <Route path={"/"} component={WebRouter} />
      </Switch>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
