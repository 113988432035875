import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import M from "materialize-css";
import Config from "../../../config/Config";
import Breadcrumb from "../../components/Breadcrumb";
import { editorPlugins, editorToolbar } from "../../helpers";

function EditCourse() {
  const history = useHistory();
  const { id } = useParams();
  // State Variable
  const [isUpdated, setIsUpdated] = useState(true);

  const [formData, setFormData] = useState({
    name: "",
    fullName: "",
    slug: "",
    duration: "",
    printFontSize: "",
    certificateFileName: "",

    // SEO Details
    seoTitle: "",
    seoDescription: "",
    seoTags: "",
  });
  const editorRef = useRef(null);
  const [description, setDescription] = useState("");
  const titleChangeHandler = (evt) => {
    const value = evt.target.value;
    setFormData({
      ...formData,
      slug: value
        .toLowerCase()
        .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
        .replace(/\s+/g, "-"),
      name: value,
    });
  };

  // Submit Handler
  const submitHandler = (evt) => {
    setIsUpdated(false);
    evt.preventDefault();
    const courseData = {
      name: formData.name,
      fullName: formData.fullName,
      slug: formData.slug,
      duration: formData.duration,
      printFontSize: formData.printFontSize,
      certificateFileName: formData.certificateFileName,
      description: formData.description,
      seoTitle: formData.seoTitle,
      seoDescription: formData.seoDescription,
      seoTags: formData.seoTags,
      status: formData.status,
    };

    fetch(`${Config.SERVER_URL}/courses/${formData._id}`, {
      method: "PUT",
      body: JSON.stringify(courseData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsUpdated(true);
        },
        (error) => {
          setIsUpdated(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // get course
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/courses/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setFormData(result.body);
            setDescription(result?.body?.description);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, [id]);

  return (
    <div className="page-wrapper px-0 pt-0">
      <div className={"container-fluid"}>
        {/* Bread crumb and right sidebar toggle */}
        <Breadcrumb title={"COURSES"} pageTitle={"Add Course"} />

        {/* End Bread crumb and right sidebar toggle */}

        {/* Course Form */}
        <div className="row mt-2">
          <div className={"col-md-12 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* COURSE DETAILS */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>COURSE DETAILS</h3>
                </div>
                {/* COURSE NAME */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    COURSE NAME
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={titleChangeHandler}
                    className="form-control"
                    placeholder={"Enter course name"}
                  />
                </div>
                {/* COURSE FULL NAME */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    COURSE FULL NAME
                  </label>
                  <input
                    type="text"
                    value={formData.fullName}
                    onChange={(evt) => {
                      setFormData({ ...formData, fullName: evt.target.value });
                    }}
                    className="form-control"
                    placeholder={"Enter course full name"}
                  />
                </div>
                {/* COURSE SLUG */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    COURSE SLUG
                  </label>
                  <input
                    type="text"
                    value={formData.slug}
                    onChange={(evt) =>
                      setFormData({ ...formData, slug: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"Enter course slug"}
                  />
                </div>
                {/* DURATION */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    DURATION
                  </label>
                  <input
                    type="number"
                    value={formData.duration}
                    onChange={(evt) =>
                      setFormData({ ...formData, duration: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"Enter course duration"}
                  />
                </div>
                {/* FONT SIZE FOR PRINT */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    FONT SIZE FOR PRINT
                  </label>
                  <input
                    type="number"
                    value={formData.printFontSize}
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        printFontSize: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Enter font size"}
                  />
                </div>

                {/* CERTIFICATE FILE NAME */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    CERTIFICATE FILE NAME
                  </label>
                  <input
                    type="text"
                    value={formData.certificateFileName}
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        certificateFileName: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Enter file name"}
                  />
                </div>

                {/* SELECT STATUS */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT STATUS
                  </label>
                  <select
                    name=""
                    id=""
                    value={formData.status}
                    onChange={(evt) => {
                      setFormData({ ...formData, status: evt.target.value });
                    }}
                    className="form-control"
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Disabled</option>
                  </select>
                </div>
              </div>

              {/* COURSE DESCRIPTION */}
              <div className={"row shadow-sm bg-white mt-3 py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>COURSE DESCRIPTION</h3>
                </div>

                {/* COURSE DESCRIPTIONS */}
                <div className={"form-group col-md-12"}>
                  <label htmlFor="" className="text-dark h6 active">
                    COURSE DESCRIPTIONS
                  </label>
                  <Editor
                    apiKey="dxkecw9qym6pvb1b00a36jykem62593xto5hg5maqyksi233"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={description}
                    // value={editorValue}
                    onChange={(newValue, editor) => {
                      setFormData({
                        ...formData,
                        description: editor.getContent(),
                      });
                    }}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: editorPlugins,
                      toolbar: editorToolbar,
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
              </div>

              {/* SEO DETAILS */}
              <div className={"row shadow-sm bg-white py-3 mt-2"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>SEO DETAILS</h3>
                </div>

                {/* SEO TITLE */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>SEO TITLE</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        seoTitle: evt.target.value,
                      })
                    }
                    value={formData.seoTitle}
                    className="form-control"
                    placeholder={"Write title"}
                  />
                </div>

                {/* SEO TAGS */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SEO TAGS
                  </label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        seoTags: evt.target.value,
                      })
                    }
                    name="weight"
                    value={formData.seoTags}
                    className="form-control"
                    placeholder={"Write tags with comma separated"}
                  />
                </div>

                {/* SEO DESCRIPTION */}
                <div className={"form-group mb-3 col-md-12"}>
                  <label className={"text-dark h6"}>SEO DESCRIPTION</label>
                  <textarea
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        seoDescription: evt.target.value,
                      })
                    }
                    value={formData.seoDescription}
                    className="form-control"
                    placeholder={"Write SEO description"}
                  />
                </div>
                {/* SUBMIT BUTTON */}
                <div className={"form-group col-md-12"}>
                  <button
                    className="btn btn-info rounded px-3 py-2"
                    type={"submit"}
                  >
                    {isUpdated ? (
                      <div>
                        <i className="fas fa-plus"></i> Update Course
                      </div>
                    ) : (
                      <div>
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading..
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCourse;
