const hostname = window.location.hostname;
export default {
  SERVER_URL:
    hostname == "localhost"
      ? "http://localhost:5200/api/v1"
      : "https://geeksapi.vijayphysics.com/api/v1",
};
// export default {
//   SERVER_URL:
//     hostname !== "localhost"
//       ? "http://192.168.19.234:5200/api/v1"
//       : "https://geekscomputerapi.rulingnews.com/api/v1",
// };
