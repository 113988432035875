import React, { useState, useEffect } from "react";
import M from "materialize-css";
import { useHistory } from "react-router-dom";
import Config from "../../../config/Config";
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";
import Select from "react-select";

const AddCertificte = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [students, setStudents] = useState([]);
  const [isAdded, setIsAdded] = useState(false);

  const [formData, setFormData] = useState({});

  // Submit Handler
  const submitHandler = (evt) => {
    setLoading(true);
    evt.preventDefault();

    fetch(Config.SERVER_URL + "/certificates", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });

            setIsAdded(!isAdded);
            // history.goBack();
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // get Courses
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/courses?limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });

            setCourses(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Students
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/students?limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: `${v.name}-${v.fatherName}`, value: v._id };
            });

            setStudents(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}
        <Breadcrumb title={"CERTIFICATE"} pageTitle={"Add Certificate"} />

        {/* Add Certificate Form */}
        <div className="row">
          <div className={"col-md-11 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* Details */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>CERTIFICATE DETAILS</h3>
                </div>

                {/* STUDENT NAME */}
                {/* <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>STUDENT NAME</label>
                  <input
                    type="text"
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        studentName: event.target.value,
                      });
                    }}
                    value={formData.studentName}
                    className="form-control"
                    placeholder={"Enter student name"}
                  />
                </div> */}

                {/* FATHER NAME */}
                {/* <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>FATHER NAME</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        fatherName: evt.target.value,
                      })
                    }
                    value={formData.fatherName}
                    className="form-control"
                    placeholder={"Enter father name"}
                  />
                </div> */}

                {/* STUDENT MOBILE */}
                {/* <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>STUDENT MOBILE</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        studentMobile: evt.target.value,
                      })
                    }
                    value={formData.studentMobile}
                    className="form-control"
                    placeholder={"Enter student mobile"}
                  />
                </div> */}

                {/* STUDENT EMAIL */}
                {/* <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>STUDENT EMAIL</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        studentEmail: evt.target.value,
                      })
                    }
                    value={formData.studentEmail}
                    className="form-control"
                    placeholder={"Enter student email"}
                  />
                </div> */}

                {/* SELECT STUDENT */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT STUDENT
                  </label>
                  <div className="">
                    <Select
                      options={students}
                      onChange={({ value }) => {
                        setFormData({ ...formData, student: value });
                      }}
                    />
                  </div>
                </div>

                {/* SELECT COURSE */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT COURSE
                  </label>
                  <div className="">
                    <Select
                      options={courses}
                      onChange={({ value }) => {
                        setFormData({ ...formData, course: value });
                      }}
                    />
                  </div>
                </div>

                {/* SERIAL NUMBER */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>SERIAL NUMBER</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        serialNumber: evt.target.value,
                      })
                    }
                    value={formData.serialNumber}
                    className="form-control"
                    placeholder={"Enter serial number"}
                  />
                </div>

                {/* REGISTRATION NUMBER */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>REGISTRATION NUMBER</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        regNumber: evt.target.value,
                      })
                    }
                    value={formData.regNumber}
                    className="form-control"
                    placeholder={"Enter registration number"}
                  />
                </div>

                {/* SESSION */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>SESSION</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        session: evt.target.value,
                      })
                    }
                    value={formData.session}
                    className="form-control"
                    placeholder={"Enter session year"}
                  />
                </div>

                {/* ACCURACY */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>ACCURACY</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        accuracy: evt.target.value,
                      })
                    }
                    value={formData.accuracy}
                    className="form-control"
                    placeholder={"Example 90%"}
                  />
                </div>

                {/* AVARAGE SPEED */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>AVARAGE SPEED</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        avgSpeed: evt.target.value,
                      })
                    }
                    value={formData.avgSpeed}
                    className="form-control"
                    placeholder={"Example 30 WPM"}
                  />
                </div>

                {/* GRADE */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>GRADE</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        grade: evt.target.value,
                      })
                    }
                    value={formData.grade}
                    className="form-control"
                    placeholder={"Example A"}
                  />
                </div>

                {/* BATCH START DATE */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>BATCH START DATE</label>
                  <input
                    type="date"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        batchStartDate: evt.target.value,
                      })
                    }
                    value={formData.batchStartDate}
                    className="form-control"
                    placeholder={"Enter date"}
                  />
                </div>

                {/* BATCH END DATE */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>BATCH END DATE</label>
                  <input
                    type="date"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        batchEndDate: evt.target.value,
                      })
                    }
                    value={formData.batchEndDate}
                    className="form-control"
                    placeholder={"Enter date"}
                  />
                </div>

                {/* CERTIFICATE ISSUES DATE */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>
                    CERTIFICATE ISSUES DATE
                  </label>
                  <input
                    type="date"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        certificateIssuesDate: evt.target.value,
                      })
                    }
                    value={formData.certificateIssuesDate}
                    className="form-control"
                    placeholder={"Enter date"}
                  />
                </div>

                {/* REFERENCE PERSON NAME */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>
                    REFERENCE PERSON NAME
                  </label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        refPersonName: evt.target.value,
                      })
                    }
                    value={formData.refPersonName}
                    className="form-control"
                    placeholder={"Enter name"}
                  />
                </div>

                {/* DESCRIPTION */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>DESCRIPTION</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        description: evt.target.value,
                      })
                    }
                    value={formData.description}
                    className="form-control"
                    placeholder={"Enter description"}
                  />
                </div>

                <div className={"form-group col-md-12"}>
                  <button className="btn btn-info rounded" type={"submit"}>
                    {loading ? (
                      <Spinner />
                    ) : (
                      <div>
                        <i className="fas fa-plus"></i> Add Certificate
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCertificte;
